<template>
  <div>
    <HeroInner descrition="Gestão de Instituição" class="mt-70" />

    <div class="grid-box fluxo-recursos" style="margin-top: 0">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon
              name="fal fa-hands title-icon"
              size="32"
              colorFont="#C3C6D5"
            />
            <h2>Assinatura de Recibos</h2>
          </div>
          <div>
            <v-form @submit.prevent="searchPersons()">
              <InputText
                type="search"
                :showLoader="false"
                place-holder="Buscar doador..."
                v-model="search"
              />
            </v-form>
          </div>
        </div>
        <div class="tab-signature ml-4">
          <!-- action bar -->
          <button
            @click="tabSignature = 1"
            :class="{ active: tabSignature == 1 }"
          >
            A serem gerados
          </button>
          <button
            @click="tabSignature = 2"
            :class="{ active: tabSignature == 2 }"
          >
            Pendentes de assinatura
          </button>
          <button
            @click="tabSignature = 3"
            :class="{ active: tabSignature == 3 }"
          >
            Assinados
          </button>

          <!-- / action bar -->
        </div>

        <!-- Recibos a serem gerados -->
        <template v-if="tabSignature == 1">
          <div class="fr-list signature">
            <v-flex
              pa-4
              grow
              v-if="itemsToGenerate != undefined && itemsToGenerate.count == 0"
            >
              <h3>Nenhum recibo a ser gerado</h3>
            </v-flex>
            <template v-else>
              <v-layout
                xs3
                action-bar
                align-center
                :class="verifyScroll ? 'fixed' : ''"
              >
                <div class="d-flex xs3 pl-3 pr-3 mt-4">
                  <v-btn
                    flat
                    small
                    color="white"
                    class="btn-primary px-3"
                    title="Aceitar"
                    @click="generateReceipts()"
                    :disabled="!hasReceiptSelected"
                  >
                    <faIcon
                      type="fas"
                      class="mr-2"
                      name="file-signature"
                      size="16px"
                    />Gerar
                  </v-btn>
                  <v-flex ml-4>
                    <v-btn
                      flat
                      small
                      color="white"
                      class="btn-primary px-3"
                      title="Baixar Recibos"
                      @click="downloadNotGenerated(entityId)"
                    >
                      <faIcon
                        type="fas"
                        class="mr-2"
                        name="download"
                        size="16px"
                      />Baixar Recibos
                    </v-btn>
                  </v-flex>
                  <!-- <p v-if="!withoutPresident">
                    <strong>Presidente:</strong>
                    {{ president.documentNumber }} -
                    {{ president.name }}
                    <br />
                    <strong>Representante:</strong>
                    {{ representative.documentNumber }} -
                    {{ representative.name }}
                  </p> -->
                  <p v-if="withoutPresident">
                    <router-link
                      :to="{
                        name: routes.app.User,
                        query: { entityId: entityId },
                      }"
                      >Clique aqui para atribuir um presidente</router-link
                    >
                  </p>
                </div>
                <div>
                  <InputSelect
                    ref="stateInput"
                    textLabel="Selecione o Assinante 1"
                    :items="signers"
                    valueAttribute="personId"
                    textAttribute="name"
                    v-model="signer1"
                  ></InputSelect>
                </div>
                <div v-if="signers.length > 1" class="ml-4">
                  <InputSelect
                    ref="stateInput"
                    textLabel="Selecione o Assinante 2"
                    :items="signers"
                    valueAttribute="personId"
                    textAttribute="name"
                    v-model="signer2"
                  ></InputSelect>
                </div>
                <div v-if="signers.length > 0" class="ml-4">
                  <InputSelect
                    ref="stateInput"
                    textLabel="Selecione a forma de assinatura"
                    :items="subscriptionTypeList"
                    valueAttribute="id"
                    textAttribute="description"
                    v-model="subscriptionType"
                  ></InputSelect>
                </div>
                <v-flex>
                  <h2 class="text-xs-right text-dark my-0 f-size-22">
                    <span
                      class="text-red fw-800"
                      v-if="itemsToGenerate != undefined"
                    >
                      {{ itemsToGenerate.count }}
                    </span>
                    <span class="fw-300 ml-2">
                      {{
                        itemsToGenerate != undefined &&
                        itemsToGenerate.count > 1
                          ? "recibos"
                          : "recibo"
                      }}
                      a gerar
                    </span>
                  </h2>
                </v-flex>
              </v-layout>

              <v-expansion-panel>
                <v-expansion-panel-content
                  v-if="itemsToGenerate != undefined"
                  v-for="project in projects"
                  :key="project.institutionId + project.projectId"
                  v-show="!project.hide"
                >
                  <template v-slot:header>
                    <v-layout align-center>
                      <v-flex shrink pr-2>
                        <v-checkbox
                          class="ma-0"
                          @click.native.stop
                          @change="changeCheckedProjectGenerate(project)"
                          v-model="project.checkedAll"
                          :disabled="withoutPresident"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex mw-half>
                        <h5 class="text-red">{{ project.institutionName }}</h5>
                        <div class="mt-2">
                          <strong>{{ project.projectName }}</strong>
                        </div>
                      </v-flex>
                      <v-flex
                        text-xs-right
                        text-color-dark
                        fw-600
                        f-size-16
                        nowrap
                      >
                        <div class="text-gray">
                          Doações:
                          <span class="text-dark">
                            <strong>{{ project.count }}</strong>
                          </span>
                        </div>

                        <div class="text-red">
                          Valor Total:
                          <span class="text-dark">
                            <strong>
                              R$
                              {{ formatter.formatMoney(project.total) }}
                            </strong>
                          </span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </template>
                  <v-card>
                    <v-card-text
                      style="padding-left: 42px !important"
                      v-for="cc in project.campaignCompanies"
                      :key="cc.companyId"
                      v-show="!cc.hide"
                    >
                      <v-expansion-panel focusable>
                        <v-expansion-panel-content>
                          <template v-slot:header>
                            <v-layout align-center>
                              <v-flex shrink pr-2>
                                <v-checkbox
                                  @click.native.stop
                                  class="ma-0"
                                  v-model="cc.checkedAll"
                                  @change="
                                    changeCheckedCampaignCompanyGenerate(cc)
                                  "
                                  :disabled="withoutPresident"
                                ></v-checkbox>
                              </v-flex>
                              <v-flex mw-half>
                                <div class="text-gray">
                                  {{ cc.companyName }}
                                </div>
                                <h5>
                                  <strong>{{ cc.campaignName }}</strong>
                                </h5>
                              </v-flex>
                              <v-flex mw-half></v-flex>
                              <v-flex text-xs-right text-red f-size-16 nowrap>
                                <div class="text-gray">
                                  Doações:
                                  <span class="text-dark">
                                    <strong>{{ cc.receipts.length }}</strong>
                                  </span>
                                </div>

                                <div class="text-gray">
                                  Valor:
                                  <span class="text-dark">
                                    <strong>
                                      R$
                                      {{ formatter.formatMoney(cc.total) }}
                                    </strong>
                                  </span>
                                </div>
                              </v-flex>
                            </v-layout>
                          </template>
                          <v-card>
                            <v-card-text
                              style="padding-left: 42px !important"
                              v-for="receipt in cc.receipts"
                              :key="receipt.receiptId"
                              v-show="!receipt.hide"
                            >
                              <v-expansion-panel focusable>
                                <v-expansion-panel-content expand-icon>
                                  <template v-slot:header>
                                    <v-layout align-center>
                                      <v-flex shrink pr-2>
                                        <v-checkbox
                                          class="ma-0"
                                          v-model="receipt.checkedValue"
                                          :value="
                                            cc.checkedAll ||
                                            receipt.checkedValue
                                          "
                                          :disabled="withoutPresident"
                                        ></v-checkbox>
                                      </v-flex>
                                      <v-flex>
                                        <h5 class="display-flex">
                                          <strong>
                                            #{{ receipt.cartItemId }}
                                            -&nbsp;
                                          </strong>
                                          <h4>{{ receipt.namePerson }}</h4>
                                        </h5>
                                        <h5>
                                          CPF:
                                          {{ receipt.documentNumberPerson }}
                                        </h5>
                                      </v-flex>

                                      <div
                                        class="
                                          d-flex
                                          text-xs-right text-red
                                          f-size-16
                                          nowrap
                                        "
                                      >
                                        <div>
                                          <div class="text-gray">
                                            Valor:
                                            <span class="text-dark">
                                              <strong>
                                                R$
                                                {{
                                                  formatter.formatMoney(
                                                    receipt.amount
                                                  )
                                                }}
                                              </strong>
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          class="upload-bar-gallery pa-0 ml-3"
                                        >
                                          <label
                                            :for="'upload'"
                                            @click="
                                              selectReceiptToUpload(receipt)
                                            "
                                            style="padding: 10px"
                                          >
                                            <i
                                              class="fal fa-cloud-upload"
                                              style="font-size: 16px"
                                            ></i>
                                          </label>
                                          <input
                                            type="file"
                                            :id="'upload'"
                                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .html, .xlsx, .xls, .csv"
                                            @change="
                                              fileChanged(
                                                $event,
                                                receipt.cartItemId
                                              )
                                            "
                                          />
                                        </div>
                                        <div
                                          class="upload-bar-gallery pa-0 ml-3"
                                        >
                                          <label
                                            :for="'upload'"
                                            style="padding: 10px"
                                          >
                                            <a
                                              target="_blank"
                                              title="Preview"
                                              :href="
                                                getUrlPreviewReceipt(receipt)
                                              "
                                            >
                                              <i
                                                class="fal fa-eye"
                                                style="font-size: 16px"
                                              ></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                    </v-layout>
                                  </template>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </div>
        </template>

        <!-- Recibos a serem assinados -->
        <template v-if="tabSignature == 2">
          <div class="fr-list signature">
            <p v-if="receipsGenerating.length > 0">
              Existem {{ receipsGenerating.length }} recibos sendo gerados,
              recarregue a pagina em alguns minutos
            </p>
            <v-flex pa-4 grow v-if="digitalReceiptList.length == 0 && eletronicReceiptList.length == 0">
              <h3>Nenhum recibo a assinar</h3>
            </v-flex>
            <template v-else>
              <v-layout
                action-bar
                align-center
                :class="verifyScroll ? 'fixed' : ''"
              >
                <v-flex grow pl-3>
                  <v-btn
                    flat
                    fab
                    small
                    color="white"
                    class="btn-red"
                    title="Rejeitar"
                    v-if="false"
                  >
                    <faIcon type="fas" name="thumbs-down" size="16px" />
                  </v-btn>
                  <v-btn
                    v-if="checkPersonIsSigner && subscriptionTypeToSigne == 1"
                    flat
                    small
                    color="white"
                    class="btn-primary px-3"
                    title="Aceitar"
                    @click="certisignRequest()"
                    :disabled="receiptSelected.length == 0"
                  >
                    <faIcon
                      type="fas"
                      class="mr-2"
                      name="file-signature"
                      size="16px"
                    />Assinar
                  </v-btn>
                  <v-btn
                    v-else
                    flat
                    small
                    color="white"
                    class="btn-primary px-3"
                    title="Aceitar"
                    @click="signEletronic()"
                    :disabled="eletronicReceiptList.count == 0"                  
                  >
                    <faIcon
                      type="fas"
                      class="mr-2"
                      name="file-signature"
                      size="16px"
                    />Assinar
                  </v-btn>
                </v-flex>
                <div class="ml-4">
                  <InputSelect
                    ref="stateInput"
                    textLabel="Selecione a forma de assinatura"
                    :items="subscriptionTypeList"
                    valueAttribute="id"
                    textAttribute="description"
                    :value="subscriptionTypeToSigne"
                    v-model="subscriptionTypeToSigne"
                  ></InputSelect>
                </div>
                <v-flex>
                  <h2 class="text-xs-right text-dark my-0 f-size-22">
                    <span class="text-red fw-800">{{ getReceiptsCount }}</span>
                    <span class="fw-300 ml-2">
                      {{ getReceiptsCountString }}
                    </span>
                  </h2>
                </v-flex>
              </v-layout>
              <v-expansion-panel>
                <v-expansion-panel-content
                  v-for="project in receiptByType"
                  :key="project.institutionId + project.projectId"
                  v-show="!project.hide"
                >
                  <template v-slot:header>
                    <v-layout align-center>
                      <v-flex shrink pr-2>
                        <v-checkbox
                          v-if="subscriptionTypeToSigne == 1"
                          class="ma-0"
                          @click.native.stop
                          @change="changeCheckedProject(project)"
                          v-model="project.checkedAll"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex mw-half>
                        <h5 class="text-red">{{ project.institutionName }}</h5>
                        <div class="mt-2">
                          <strong>{{ project.projectName }}</strong>
                        </div>
                      </v-flex>
                      <v-flex
                        text-xs-right
                        text-color-dark
                        fw-600
                        f-size-16
                        nowrap
                      >
                        <div class="text-gray">
                          Doações:
                          <span class="text-dark">
                            <strong>{{ project.count }}</strong>
                          </span>
                        </div>

                        <div class="text-red">
                          Valor Total:
                          <span class="text-dark">
                            <strong>
                              R$
                              {{ formatter.formatMoney(project.total) }}
                            </strong>
                          </span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </template>
                  <v-card>
                    <v-card-text
                      style="padding-left: 42px !important"
                      v-for="cc in project.campaignCompanies"
                      :key="cc.companyId"
                      v-show="!cc.hide"
                    >
                      <v-expansion-panel focusable>
                        <v-expansion-panel-content>
                          <template v-slot:header>
                            <v-layout align-center>
                              <v-flex shrink pr-2>
                                <v-checkbox
                                  v-if="subscriptionTypeToSigne == 1"
                                  @click.native.stop
                                  class="ma-0"
                                  v-model="cc.checkedAll"
                                  @change="changeCheckedCampaignCompany(cc)"
                                ></v-checkbox>
                              </v-flex>
                              <v-flex mw-half>
                                <div class="text-gray">
                                  {{ cc.companyName }}
                                </div>
                                <h5>
                                  <strong>{{ cc.campaignName }}</strong>
                                </h5>
                              </v-flex>
                              <v-flex mw-half></v-flex>
                              <v-flex text-xs-right text-red f-size-16 nowrap>
                                <div class="text-gray">
                                  Doações:
                                  <span class="text-dark">
                                    <strong>{{ cc.receipts.length }}</strong>
                                  </span>
                                </div>

                                <div class="text-gray">
                                  Valor:
                                  <span class="text-dark">
                                    <strong>
                                      R$
                                      {{ formatter.formatMoney(cc.total) }}
                                    </strong>
                                  </span>
                                </div>
                              </v-flex>
                            </v-layout>
                          </template>
                          <v-card>
                            <v-card-text
                              style="padding-left: 42px !important"
                              v-for="receipt in cc.receipts"
                              :key="receipt.receiptId"
                              v-show="!receipt.hide"
                            >
                              <v-expansion-panel focusable>
                                <v-expansion-panel-content expand-icon>
                                  <template v-slot:header>
                                    <v-layout align-center>
                                      <v-flex shrink pr-2>
                                        <v-checkbox
                                          v-if="subscriptionTypeToSigne == 1"
                                          class="ma-0"
                                          v-model="receipt.checkedValue"
                                          :value="
                                            cc.checkedAll ||
                                            receipt.checkedValue
                                          "
                                        ></v-checkbox>
                                      </v-flex>
                                      <v-flex>
                                        <h5 class="display-flex">
                                          <strong>
                                            #{{ receipt.receiptId }}
                                            -&nbsp;
                                          </strong>
                                          <h4>{{ receipt.namePerson }}</h4>
                                        </h5>
                                        <h5>
                                          CPF:
                                          {{ receipt.documentNumberPerson }}
                                        </h5>
                                      </v-flex>
                                      <v-flex>
                                        <h5 class="display-flex" v-if="receipt.subscriptionType == 1">
                                          <strong>
                                            Assinatura Digital
                                          </strong>
                                        </h5>
                                        <h5 class="display-flex" v-else>
                                          <strong>
                                            Assinatura Eletrônica
                                          </strong>
                                        </h5>
                                      </v-flex>

                                      <v-flex
                                        text-xs-right
                                        text-red
                                        f-size-16
                                        nowrap
                                      >
                                        <div>
                                          <div>
                                            <div class="text-gray">
                                              Recibo:
                                              <span class="text-dark">
                                                <strong
                                                  >#{{
                                                    receipt.receiptId
                                                  }}</strong
                                                >
                                              </span>
                                            </div>

                                            <div class="text-gray">
                                              Valor:
                                              <span class="text-dark">
                                                <strong>
                                                  R$
                                                  {{
                                                    formatter.formatMoney(
                                                      receipt.amount
                                                    )
                                                  }}
                                                </strong>
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            <button
                                              class="btn-line small mr-2"
                                              title="Restaurar"
                                              @click="
                                                restoreReceipt(
                                                  receipt.cartItemId
                                                )
                                              "
                                            >
                                              <i
                                                class="fal fa-ban text-red"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </template>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </div>
        </template>

        <!-- Recibos já assinados -->
        <template v-if="tabSignature == 3">
          <div class="fr-list signature">
            <v-layout
              action-bar
              align-center
              :class="verifyScroll ? 'fixed' : ''"
              pb-2
            >
              <v-flex xs1 pl-3>
                <InputSelect
                  :items="yearsAvailable"
                  ref="yearInput"
                  textLabel="Ano"
                  v-model="yearSelected"
                  valueAttribute="year"
                  textAttribute="year"
                />
              </v-flex>
              <v-flex xs3 ml-4>
                <v-btn
                  flat
                  small
                  color="white"
                  class="btn-primary px-3"
                  title="Baixar Recibos"
                  @click="zipDownload()"
                  :disabled="itemsSigned.count == 0"
                >
                  <faIcon
                    type="fas"
                    class="mr-2"
                    name="download"
                    size="16px"
                  />Baixar Recibos
                </v-btn>
              </v-flex>
              <!-- <v-flex xs3 pl-3>
                <InputText
                  type="text"
                  ref="initialDateInput"
                  textLabel="Data Inicial"
                  v-model="initialDate"
                  :mask="'##/##/####'"
                />
              </v-flex>
              <v-flex xs3 pl-3>
                <InputText
                  type="text"
                  ref="finalDateInput"
                  textLabel="Data Final"
                  v-model="finalDate"
                  :mask="'##/##/####'"
                />
              </v-flex> -->
            </v-layout>
            <v-flex pa-4 grow v-if="itemsSigned.count == 0">
              <h3>Nenhum recibo assinado neste ano</h3>
            </v-flex>
            <template v-else>
              <v-expansion-panel>
                <!-- Campanhas -->
                <v-expansion-panel-content
                  v-for="project in itemsSigned.projects"
                  :key="project.institutionId + project.projectId"
                  v-show="!project.hide"
                >
                  <template v-slot:header>
                    <v-layout align-center>
                      <v-flex mw-half>
                        <h5 class="text-red">{{ project.institutionName }}</h5>
                        <div class="mt-2">
                          <strong>{{ project.projectName }}</strong>
                        </div>
                      </v-flex>
                      <v-flex
                        text-xs-right
                        text-color-dark
                        fw-600
                        f-size-16
                        nowrap
                      >
                        <div class="text-gray">
                          Doações:
                          <span class="text-dark">
                            <strong>{{ project.count }}</strong>
                          </span>
                        </div>
                        <div class="text-red">
                          Valor Total:
                          <span class="text-dark">
                            <strong>
                              R$
                              {{ formatter.formatMoney(project.total) }}
                            </strong>
                          </span>
                        </div>
                      </v-flex>
                    </v-layout>
                  </template>
                  <v-card>
                    <v-card-text
                      style="padding-left: 42px !important"
                      v-for="cc in project.campaignCompanies"
                      :key="cc.companyId"
                      v-show="!cc.hide"
                    >
                      <v-expansion-panel focusable>
                        <v-expansion-panel-content>
                          <template v-slot:header>
                            <v-layout align-center>
                              <v-flex mw-half>
                                <div class="text-gray">
                                  {{ cc.companyName }}
                                </div>
                                <h5>
                                  <strong>{{ cc.campaignName }}</strong>
                                </h5>
                              </v-flex>
                              <v-flex mw-half></v-flex>
                              <v-flex text-xs-right text-red f-size-16 nowrap>
                                <div class="text-gray">
                                  Doações:
                                  <span class="text-dark">
                                    <strong>{{ cc.receipts.length }}</strong>
                                  </span>
                                </div>

                                <div class="text-gray">
                                  Valor:
                                  <span class="text-dark">
                                    <strong>
                                      R$
                                      {{ formatter.formatMoney(cc.total) }}
                                    </strong>
                                  </span>
                                </div>
                              </v-flex>
                            </v-layout>
                          </template>
                          <v-card>
                            <v-card-text
                              style="padding-left: 42px !important"
                              v-for="receipt in cc.receipts"
                              :key="receipt.receiptId"
                              v-show="!receipt.hide"
                            >
                              <v-expansion-panel focusable>
                                <v-expansion-panel-content expand-icon>
                                  <template v-slot:header>
                                    <v-layout align-center>
                                      <v-flex>
                                        <h5 class="display-flex">
                                          <strong>
                                            #{{ receipt.receiptId }}
                                            -&nbsp;
                                          </strong>
                                          <h4>{{ receipt.namePerson }}</h4>
                                        </h5>
                                        <h5>
                                          CPF:
                                          {{ receipt.documentNumberPerson }}
                                        </h5>
                                        <div
                                          v-if="
                                            receipt.numberOfNumberOfSignRequired !==
                                            0
                                          "
                                        >
                                          <h5 class="display-flex">
                                            <strong>
                                              Assinatura 1:
                                              {{ receipt.signer1.name }} /
                                              {{
                                                receipt.signer1.done
                                                  ? receipt.signer1.signDate
                                                  : ""
                                              }}
                                            </strong>
                                          </h5>

                                          <h5
                                            class="display-flex"
                                            v-if="receipt.signer2"
                                          >
                                            <strong>
                                              Assinatura 2:
                                              {{ receipt.signer2.name }} /
                                              {{
                                                receipt.signer2.done
                                                  ? receipt.signer2.signDate
                                                  : ""
                                              }}
                                            </strong>
                                          </h5>
                                        </div>
                                        <div v-else>
                                          <h5 class="display-flex">
                                            <strong
                                              >Assinado Manualmente</strong
                                            >
                                          </h5>
                                        </div>
                                      </v-flex>

                                      <v-flex
                                        text-xs-right
                                        text-red
                                        f-size-16
                                        nowrap
                                      >
                                        <div>
                                          <div>
                                            <div class="text-gray">
                                              Recibo:
                                              <span class="text-dark">
                                                <strong
                                                  >#{{
                                                    receipt.cartItemId
                                                  }}</strong
                                                >
                                              </span>
                                            </div>

                                            <div class="text-gray">
                                              Valor:
                                              <span class="text-dark">
                                                <strong>
                                                  R$
                                                  {{
                                                    formatter.formatMoney(
                                                      receipt.amount
                                                    )
                                                  }}
                                                </strong>
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            <button
                                              class="btn-line small mr-2"
                                              title="Restaurar"
                                              @click="
                                                restoreReceipt(
                                                  receipt.cartItemId
                                                )
                                              "
                                            >
                                              <i
                                                class="fal fa-ban text-red"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </template>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </div>
        </template>
      </v-layout>
    </div>

    <!-- Modal Filters -->
    <AucAlert
      ref="aucAlert"
      :title="aucAlertText"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="executeAction()"
      @cancel="resetCheckbox"
    />
  </div>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import HeroInner from "@/components/HeroInner.vue";
import DocService from "@/scripts/services/doc.service";
import EntityService from "@/scripts/services/entity.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "../../../scripts/helpers/common.helper";
import File from "@/scripts/models/file.model";
import { mapGetters } from 'vuex';

export default {
  components: {
    AucAlert,
    HeroInner,
  },
  props: {
    institutionId: Number,
    search: String,
    items: Object,
    itemsSigned: Object,
    itemsToGenerate: Object,
    cpf: null,
    hasSigned: null,
  },
  data() {
    return {
      verifyScroll: false,
      docService: new DocService(),
      entityService: new EntityService(),
      formatter: new FormatterHelper(),
      tabSignature: 1,
      withoutPresident: false,
      president: { name: null, documentNumber: null },
      representative: { name: null, documentNumber: null },
      routes: Routes,
      receipsGenerating: [],
      yearSelected: 2020,
      file: new File(),
      receiptToUpload: null,
      projects: [],
      entityId: 0,
      entity: {},
      signers: [],
      signer1: null,
      signer2: null,
      initialDate: "09/12/2022",
      finalDate: "09/12/2022",
      subscriptionTypeList: [ {id: 1, description: "Assinatura Digital (Certificado)"}, {id: 2, description: "Assinatura Eletrônica"}],
      subscriptionType: 1,
      subscriptionTypeToSigne: 1,
      digitalReceiptList: [],
      eletronicReceiptList: [],
    };
  },
  created() {
    this.items = { personIsSigner1: false, PersonIsSigner2: false };
    this.search = "";
    window.addEventListener("scroll", this.handleScroll);
    //this.institutionId = this.$route.query.institutionId;
    if (this.$route.query.entityId != undefined)
      this.entityId = this.$route.query.entityId;

    if (this.entityId > 0) {
      this.loadReceipts(this.entityId);
      this.loadSigners(this.entityId);
      this.loadEntity(this.entityId);
    }
  },
  computed: {
    ...mapGetters([
      'userProfile'
    ]),
    checkPersonIsSigner(){
        return this.eletronicReceiptList.personIsSigner1 || 
        this.eletronicReceiptList.personIsSigner2 ||
        this.digitalReceiptList.personIsSigner1 ||
        this.digitalReceiptList.personIsSigner2;
    },
    getReceiptsCountString(){
      if (this.subscriptionTypeToSigne == 2) {
        if (this.eletronicReceiptList.count > 1) {
          return "Recibos a assinar"
        }

        return "Recibo a assinar"
      }
      
      if (this.subscriptionTypeToSigne == 1) {
        if (this.digitalReceiptList.count > 1) {
          return "Recibos a assinar"
        }

        return "Recibo a assinar"
      }
      return '';
    },
    getReceiptsCount(){
      if (this.subscriptionTypeToSigne == 2) {
        return this.eletronicReceiptList.count;
      }

      return this.digitalReceiptList.count
    },
    receiptByType(){
      if (this.subscriptionTypeToSigne == 2) {
        return this.eletronicReceiptList.projects;
      }
      return this.digitalReceiptList.projects;
    },
    yearsAvailable() {
      const year = new Date().getFullYear();
      let result = [];
      let index = 2019;

      while (index <= year) {
        result.push({ year: index });
        index++;
      }

      return result;
    },
    hasReceiptSelected() {
      let cartItemIds = this.getCartItemIdFromCheckedsToGenerate();
      if (cartItemIds.length > 0) return true;
      return false;
    },
    receiptSelected() {
      let docs = [];
      var receipts = this.getReceiptList()
      for (let p = 0; p < receipts.projects.length; p++) {
        let project = receipts.projects[p];
        for (let c = 0; c < project.campaignCompanies.length; c++) {
          let company = project.campaignCompanies[c];
          for (let r = 0; r < company.receipts.length; r++) {
            let receipt = company.receipts[r];
            if (receipt.checkedValue) {
              docs.push(receipt.certisignKey);
            }
          }
        }
      }
      return docs;
    },
  },
  methods: {
    getReceiptList(){
      return this.subscriptionTypeToSigne == 2 ? this.eletronicReceiptList : this.digitalReceiptList;
    },
    loadEntity(entityId){
      this.entityService.findById((data) => {
        this.entity = data;
      }, entityId)
    },
    loadReceipts(entityId) {
      this.docService
        .findAllPendingSignatureAlt(entityId)
        .then(this.callbackPending);

      this.docService.findAllSigned(
        entityId,
        this.yearSelected,
        this.callbackSigned
      );
      this.docService.findAllToGenerate(entityId, this.callbackToGenerate);
      this.docService.findReceiptsGenerating(
        entityId,
        this.callbackToReceiptsGenerating
      );
    },
    loadSigners(entityId) {
      this.docService.getSignersByEntityId(entityId).then((data) => {
        if (data.length >= 1) {
          this.president = data[0];
          this.signers = data;
        } else this.withoutPresident = true;
        if (data.length >= 2) this.representative = data[1];
        if (data.length == 0) this.withoutPresident = true;
      });
    },
    callbackPending(data) {
      this.items = data[0];
      this.digitalReceiptList = data[0];
      this.eletronicReceiptList = data[1];

      if (this.subscriptionTypeToSigne == 2) {
        this.items = data[1];
      }
    },
    callbackSigned(data) {
      this.itemsSigned = data;
    },
    callbackToGenerate(data) {
      this.itemsToGenerate = data;
    },
    zipDownload() {
      this.docService
        .downloadZipReceipts(this.entityId, this.yearSelected)
        .then((data) => {
          CommonHelper.swal(
            "",
            "Você receberá um email com os recibos",
            "success"
          );
        });
    },
    signEletronic(){
        window.open(this.entity.certisignSignUrl, '_blank').focus();
    },
    certisignRequest() {
      var url = process.env.VUE_APP_API_CERTISIGN;

      var confirmConfig = {
        textOkButton: "Sim",
        textCancelButton: "Não",
        textMessage:
          "Interromper o processo de assinatura causará inconsistência nos status das assinaturas já efetuadas. No entanto, da próxima vez que tentar assinar os status serão atualizados. Deseja realmente interromper o processo de assinatura em lote?",
      };
      this.addCertisignCSS();
      
      var docs = this.receiptSelected;
      var signeds = [];
      const self = this;

      this.docService.getSignerByReceiptId(docs).then((data) => {
        if (data) {
          let signer = null;
          for (let index = 0; index < data.length; index++) {
            const sig = data[index].cpf.replace(/\D/g, '');
            if(sig == this.userProfile.cpfOrCNPJ)
              signer = sig
          }
          if(signer == null)
            signer = data[0]

          new window.CertisignIntegration(url, 560, 560, false, confirmConfig)
            .sign(docs, signer)
            .onSignCompleted(function (signStatus) {
              if (Array.isArray(signStatus)) {
                for (var i = 0; i < signStatus.length; i++) {
                  var result = signStatus[i];
                  if (result == 0 || result == 1) {
                    signeds.push(docs[i]);
                  }
                }
              } else {
                if (signStatus == 0 || signStatus == 1) {
                  signeds.push(docs[0]);
                }
              }
              if (signeds.length > 0) {
                self.saveSigned(signeds);
              }
              console.log(signeds);
            });
        }
      });
    },
    addCertisignCSS(){
      var link = document.createElement( "link" );
      link.href = "https://portaldeassinaturas.com.br/Content/Integration/certisign-client-integration-min.css?v=2";
      link.type = "text/css";
      link.rel = "stylesheet";
      link.media = "screen,print";
      link.id = "css-certisign"
      document.getElementsByTagName( "head" )[0].appendChild( link );
    },
    generateReceipts() {
      let cartItemIds = this.getCartItemIdFromCheckedsToGenerate();
      var signerList = [this.signer1];

      if (this.signers.length > 1 && this.signer2) {
        signerList.push(this.signer2);
      }

      var args = { cartItemsIds: cartItemIds, personIds: signerList, entityId: this.entityId, subscriptionType: this.subscriptionType };
      this.docService.generateReceipts(args).then((data) => {
        this.docService.findAllToGenerate(
          this.entityId,
          this.callbackToGenerate
        );
        CommonHelper.swal("", "Os recibos estão sendo gerados", "success");
      });
    },
    saveSigned(docs) {
      this.docService.saveReceiptSigned(this.callbackSaveReceipts, docs);
    },
    callbackSaveReceipts(data) {
      this.loadReceipts(this.entityId);
    },
    callbackToReceiptsGenerating(data) {
      this.receipsGenerating = data;
    },
    changeCheckedProject(proj) {
      var checked = proj.checkedAll;
      var receiptList = this.getReceiptList();
      for (let p = 0; p < receiptList.projects.length; p++) {
        let project = receiptList.projects[p];
        if (
          project.projectId == proj.projectId &&
          project.institutionId == proj.institutionId
        ) {
          for (let c = 0; c < project.campaignCompanies.length; c++) {
            let company = project.campaignCompanies[c];
            company.checkedAll = checked;

            for (let r = 0; r < company.receipts.length; r++) {
              let receipt = company.receipts[r];
              receipt.checkedValue = checked;
            }
          }
        }
      }
    },
    changeCheckedProjectGenerate(proj) {
      var checked = proj.checkedAll;

      if (this.itemsToGenerate == undefined) return;

      for (let p = 0; p < this.itemsToGenerate.projects.length; p++) {
        let project = this.itemsToGenerate.projects[p];
        if (
          project.projectId == proj.projectId &&
          project.institutionId == proj.institutionId
        ) {
          for (let c = 0; c < project.campaignCompanies.length; c++) {
            let company = project.campaignCompanies[c];
            company.checkedAll = checked;

            for (let r = 0; r < company.receipts.length; r++) {
              let receipt = company.receipts[r];
              receipt.checkedValue = checked;
            }
          }
        }
      }
    },
    changeCheckedCampaignCompany(campaignCompany) {
      var checked = campaignCompany.checkedAll;
      var receiptList = this.getReceiptList();
      for (let p = 0; p < receiptList.projects.length; p++) {
        let project = receiptList.projects[p];

        for (let c = 0; c < project.campaignCompanies.length; c++) {
          let company = project.campaignCompanies[c];

          if (
            company.campaignId == campaignCompany.campaignId &&
            company.companyId == campaignCompany.companyId
          ) {
            company.checkedAll = checked;
            for (let r = 0; r < company.receipts.length; r++) {
              let receipt = company.receipts[r];
              receipt.checkedValue = checked;
            }
          }
        }
      }
    },
    changeCheckedCampaignCompanyGenerate(campaignCompany) {
      var checked = campaignCompany.checkedAll;

      if (this.itemsToGenerate == undefined) return;

      for (let p = 0; p < this.itemsToGenerate.projects.length; p++) {
        let project = this.itemsToGenerate.projects[p];

        for (let c = 0; c < project.campaignCompanies.length; c++) {
          let company = project.campaignCompanies[c];

          if (
            company.campaignId == campaignCompany.campaignId &&
            company.companyId == campaignCompany.companyId
          ) {
            company.checkedAll = checked;
            for (let r = 0; r < company.receipts.length; r++) {
              let receipt = company.receipts[r];
              receipt.checkedValue = checked;
            }
          }
        }
      }
    },
    searchPersons() {
      var receiptList = this.getReceiptList()
      for (let p = 0; p < receiptList.projects.length; p++) {
        let project = receiptList.projects[p];
        let contInProject = 0;
        for (let c = 0; c < project.campaignCompanies.length; c++) {
          let company = project.campaignCompanies[c];
          let contInCompany = 0;
          for (let r = 0; r < company.receipts.length; r++) {
            let receipt = company.receipts[r];
            if (
              this.search == "" ||
              (receipt.namePerson + receipt.documentDocumentPerson)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              receipt.hide = false;
              contInCompany++;
              contInProject++;
            } else {
              receipt.hide = true;
            }
          }
          company.hide = contInCompany > 0 ? false : true;
        }
        project.hide = contInProject > 0 ? false : true;
      }

      for (let p = 0; p < this.itemsSigned.projects.length; p++) {
        let project = this.itemsSigned.projects[p];
        let contInProject = 0;
        for (let c = 0; c < project.campaignCompanies.length; c++) {
          let company = project.campaignCompanies[c];
          let contInCompany = 0;
          for (let r = 0; r < company.receipts.length; r++) {
            let receipt = company.receipts[r];
            if (
              this.search == "" ||
              (receipt.namePerson + receipt.documentDocumentPerson)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              receipt.hide = false;
              contInCompany++;
              contInProject++;
            } else {
              receipt.hide = true;
            }
          }
          company.hide = contInCompany > 0 ? false : true;
        }
        project.hide = contInProject > 0 ? false : true;
      }
    },
    getCartItemIdFromCheckedsToGenerate() {
      let cartItemIds = [];

      if (this.itemsToGenerate == undefined) {
        return cartItemIds;
      }

      this.itemsToGenerate.projects.forEach((project) => {
        project.campaignCompanies.forEach((campaign) => {
          campaign.receipts.forEach((receipt) => {
            if (receipt.checkedValue) cartItemIds.push(receipt.cartItemId);
          });
        });
      });

      return cartItemIds;
    },
    selectReceiptToUpload(receipt) {
      this.receiptToUpload = receipt;
    },
    fileChanged(event) {
      let cartItemId = this.receiptToUpload.cartItemId;
      var file = event.target.files[0];
      let formData = this.mountFormData(file, cartItemId);

      this.docService.saveReceiptSignedManual(formData).then(() => {
        this.loadReceipts(this.entityId);
        CommonHelper.swal("", "O recibo foi enviado", "success");
      });
    },
    mountFormData(file, cartItemId) {
      let formData = new FormData();
      formData.append("streamFile", file);
      formData.append("file.name", file.name);
      formData.append("cartItemId", cartItemId);
      console.log(formData);

      return formData;
    },
    downloadNotGenerated(entityId) {
      this.docService
        .downloadNotGeneratedReceipts(entityId, this.signer1)
        .then((response) => {
          this.downloadBlobZip("Recibos a serem gerados", response);
        });
    },
    downloadBlobZip(nameFile, response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/zip" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile + ".zip");
      document.body.appendChild(link);
      link.click();
    },
    restoreReceipt(cartItemId) {
      this.docService.restoreReceipt(cartItemId).then(() => {
        this.loadReceipts(this.entityId);
        CommonHelper.swal("", "O recibo foi restaurado", "success");
      });
    },
    getUrlPreviewReceipt(receipt) {
      return (
        process.env.VUE_APP_API_URL +
        "Doc/Receipt?cartItemId=" +
        receipt.cartItemId
      );
    },
  },

  watch: {
    itemsToGenerate: function (val) {
      if (val) this.projects = val.projects;
      else this.projects = [];
    },
    tabSignature: function (val) {
      if (val === 2)
        this.docService.findReceiptsGenerating(
          this.entityId,
          this.callbackToReceiptsGenerating
        );
    },
    yearSelected: function (val) {
      this.docService.findAllSigned(
        this.entityId,
        this.yearSelected,
        this.callbackSigned
      );
    },
    hasSigned: function (val) {
      console.log("finalizado");
    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
